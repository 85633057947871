<template>
  <div>
    <div class="header flex-spa-ver-cen">
      <div class="avatar" @click="handleAvatar"><img :src="avatar" alt=""/></div>

      <!-- <van-dropdown-menu>
        <van-dropdown-item v-model="value" :options="option" @change="handleRouterChange" />
      </van-dropdown-menu> -->
      <SelectDown/>
      <!-- <van-popover
        class="header-popover"
        v-model="showPopover"
        trigger="click"
        :actions="option"
        @select="onSelect"
      >
        <template #reference>
          <div class="flex-ver-cen menu-select">
            {{ selectMenu }} <van-icon name="arrow-down" class="work-icon" />
          </div>
        </template>
      </van-popover> -->
      <div class="flex">
        <div class="attendance_icon mrg-r-20" @click="handleGo('/attendance')"
             v-if="(this.$auth.hasPermi('hr:checkwork:add') || this.$auth.hasPermi('hr:checkwork:out')) && isShowAuth">
          <img src="../../assets/images/attendance_icon.png" alt=""/>
          <div style="font-size: 12px; width: 30px">打卡</div>
        </div>
        <div class="notice" @click="handleGo('/notice')" v-if="false">
          <img src="../../assets/images/notice_img.png" alt=""/>
        </div>
      </div>
    </div>

    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="moudle != ''">
      <template v-if="moudle == 'crm'">
        <van-swipe-item>
          <img src="../../assets/images/crm_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/crm_banner3.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/crm_banner4.png"/>
        </van-swipe-item>
      </template>

      <template v-if="moudle == 'oa'">
        <van-swipe-item>
          <img src="../../assets/images/oa_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/oa_banner3.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/oa_banner4.png"/>
        </van-swipe-item>
      </template>

      <template v-if="moudle == 'inventory'">
        <van-swipe-item>
          <img src="../../assets/images/in_banner1.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/in_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/in_banner3.png"/>
        </van-swipe-item>
      </template>

      <template v-if="moudle == 'purchase'">
        <van-swipe-item>
          <img src="../../assets/images/purchase_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/purchase_banner3.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/purchase_banner4.png"/>
        </van-swipe-item>
      </template>

      <template v-if="moudle == 'cw'">
        <van-swipe-item>
          <img src="../../assets/images/cw_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/cw_banner3.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/cw_banner4.png"/>
        </van-swipe-item>
      </template>

      <template v-if="moudle == 'report'">
        <van-swipe-item>
          <img src="../../assets/images/report_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/report_banner3.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/report_banner4.png"/>
        </van-swipe-item>
      </template>

      <template v-if="moudle == 'sale'">
        <van-swipe-item>
          <img src="../../assets/images/sale_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/sale_banner3.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/sale_banner4.png"/>
        </van-swipe-item>
      </template>

      <template v-if="moudle == 'cw'">
        <van-swipe-item>
          <img src="../../assets/images/cw_banner2.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/cw_banner3.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/images/cw_banner4.png"/>
        </van-swipe-item>
      </template>
    </van-swipe>

    <div class="children-wrap flex-wrap-ver-cen">
      <div
          class="item-child"
          v-for="(item, index) in children"
          :key="index"
          @click="handleChildClick(item)"
      >
        <img :src="require(`../../assets/oaImage/${item.imgUrl}.png`)" class="img" alt=""/>
        <div class="nav">{{ item.title || '' }}</div>
      </div>
    </div>
    <!-- 点击头像之后的弹出层内容 -->
    <van-popup v-model="show" position="left" class="popup-container">
      <div class="popup-title flex-spa-ver-cen">
        <div class="left-wrap flex-ver-cen">
          <img :src="avatar" alt="" class="avatar"/>
          <div class="user-name">
            <div class="name">{{ postName }}</div>
            <div class="dept">{{ deptName }}</div>
          </div>
        </div>
        <!--                <van-icon name="arrow"/>-->
      </div>

      <div class="cell-wrap">
        <van-cell
            v-for="(item, index) in list"
            :key="index"
            class="my-van-cell"
            @click="handleGoInner(item.path)"
        >
          <template #title>
            <div class="flex">
              <i
                  :class="[item.icon, 'iconfont', 'cell-icon', 'cell-icon1']"
                  :style="{ 'font-size': item.size }"
              ></i>
              <!-- <van-icon name="setting-o" class="mrg-r-10 cell-icon" style="font-size: 22px" /> -->
              <div class="cell-label">{{ item.name }}</div>
            </div>
          </template>
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-icon name="arrow" class="cell-icon"/>
          </template>
        </van-cell>
      </div>

      <div class="logout-btn" @click="logout">
        <van-icon name="warning-o" class="mrg-r-10"/>
        退出登录
      </div>
    </van-popup>

    <!--检查更新-->
    <van-popup v-model="showNewVersion" :close-on-click-overlay="false">
      <div class="pop-content">
        <div class="new-version-con">
          <div class="label">发现新版本</div>
          <div class="value">V {{ versionObj.versionName }}</div>
        </div>
        <div class="title">更新内容</div>
        <div class="con">
          <div class="item-con">1.界面全新改版，一目了然</div>
          <div class="item-con">2.修复一些bug，提升系统安全性能</div>
          <div class="item-con">3.消息中心改版，信息查询更清晰</div>
          <div class="item-con">4.修复一些bug，提升系统安全性能</div>
          <div class="item-con">5.界面全新改版，功能一目了然</div>
        </div>
        <a href="#" class="btn" @click.prevent="downApp()">立即更新</a>
        <div class="tips">建议在WIFI环境下载</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {fetchVersion} from "@/api/system/config";
import SelectDown from './SelectDown.vue';

export default {
  props: {
    moudle: String
  },

  components: {
    SelectDown
  },

  data() {
    return {
      value: localStorage.getItem('indexPath'),
      option: this.$store.getters.headerRouters,
      avatar: this.$store.getters.avatar,
      current: 0,
      children: [],
      showPopover: false,
      selectMenu: null,
      show: false,
      postName: localStorage.getItem('postName'),
      deptName: localStorage.getItem('deptName'),
      list: [
        /*{
            name: '设置',
            icon: 'icon-shezhi1',
            path: '',
            size: '20px',
        },
        {
            name: '扫一扫',
            icon: 'icon-saoyisao',
            path: '',
            size: '17px',
        },
        {
            name: '反馈信息',
            icon: 'icon-piliangxuanze',
            path: '',
            size: '20px',
        },*/
        {
          name: '我的消息',
          icon: 'icon-xiaoxi',
          path: '/staticSystem/msg',
          size: '20px',
        },
        {
          name: '关于壹米咻咻',
          icon: 'icon-guanyuwomen',
          path: '/staticSystem/about',
          size: '20px',
        },
        /*{
            name: '夜间模式',
            icon: 'icon-yueliang',
            path: '',
            size: '17px',
        },
        {
            name: '个性装扮',
            icon: 'icon-pifu',
            path: '',
            size: '20px',
        },
        {
            name: '联系客服',
            icon: 'icon-kefu',
            path: '',
            size: '18px',
        },
        {
            name: '修改密码',
            icon: 'icon-mima',
            path: '',
            size: '18px',
        },*/
      ],
      showNewVersion: false,
      versionObj: {},
      myVersion: localStorage.getItem('localver'),
      versionCode: -1,
      center: {lng: 117.12639941, lat: 36.6565542},
      zoom: 15,
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  watch: {
    $route: {
      handler(val) {
        this.val = val.path;
        this.selectMenu = this.option.find((ele) => ele.value == val.path).text;
        this.children = this.option.find((ele) => ele.value == val.path).children;
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    //刚进入app，检查更新
    this.getVersion();
  },

  destroyed() {
    if (this.clockTimer) {
      this.clearTimer('destroyed销毁，关闭定位定时')
    }
  },

  methods: {
    //检查更新
    async getVersion() {
      let that = this
      try {
        await mui.getJSON('./manifest.json', null, function (data) {
          let myVer = JSON.stringify(data.version.name); //之前不知道，原来要JSON.stringify()

          window.localStorage.localver = myVer; //将解析到的版本号进行存储
          that.myVersion = myVer;
          that.versionCode = JSON.stringify(data.version.code);

        });
      } catch (e) {
        that.versionCode = -1;
      }

      const result = await fetchVersion();
      this.versionObj = result.data;
      if (this.versionCode != -1 && Number(this.versionObj.versionCode) > Number(this.versionCode)) {
        this.showNewVersion = true;
      }

      //是否展示需要权限的功能，如相机、定位等
      let isShowAuth = result.data.isShowAuth
      console.log(this.versionCode, this.versionObj.versionCode)
      if (isShowAuth !== undefined && this.versionCode === this.versionObj.versionCode) {
        localStorage.setItem('isShowAuth', isShowAuth ? '1' : '0')
      } else {
        localStorage.setItem('isShowAuth', '1')
      }
    },

    //下载app
    downApp() {
      plus.runtime.openURL('https://downapp.yimixiuxiu.com');
    },

    onSelect(action) {
      this.selectMenu = action.text;
      this.children = action.children;
      this.$router.push({
        path: action.value,
      });
    },

    handleRouterChange(path) {
      this.children = this.option.find((ele) => ele.value == path).children;
      this.$router.push({
        path,
      });
    },

    handleGoInner(path) {
      this.$router.push({
        path,
      });
    },

    onChange(index) {
      this.current = index;
    },

    logout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push('/login');
        location.reload();
      });
    },

    handleAvatar() {
      this.show = true;
    },

    handleGo(path) {
      this.$router.push({path});
    },

    handleChildClick(item) {
      let obj = {path: item.path}
      if (item.path.indexOf('/statement/') != -1 || item.path.indexOf('/cw/bank') != -1) {
        obj.query = {children: item.children}
      }
      this.$router.push(obj);
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  display: block;
  width: 100%;
  height: 100%;
}

.work-icon {
  margin-left: 20px;
}

.header {
  height: 75px;
  background: #fff;
  padding: 0 20px 0 20px;

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
  }

  .notice {
    width: 32px;
    height: 34px;
  }

  .attendance_icon {
    width: 37px;
    height: 34px;
  }
}

.menu-select {
  font-size: 38px;

  .work-icon {
    font-size: 26px;
  }
}

.my-swipe {
  color: #fff;
  font-size: 20px;
  width: 710px;
  height: 260px;
  margin: 20px auto;
  text-align: center;
  border-radius: 14px;
  overflow: hidden;
}

.my-swipe .van-swipe-item {
  border-radius: 14px;
  overflow: hidden;
}

.children-wrap {
  width: 710px;
  // height: 350px;
  padding: 20px 0;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 14px 14px 14px 14px;

  .item-child {
    width: 142px;
    text-align: center;
    margin-bottom: 20px;

    img {
      display: block;
      width: 70px;
      height: 70px;
      margin: 0 auto;
    }

    .nav {
      font-size: 24px;
      color: #333333;
      text-align: center;
      margin-top: 14px;
    }
  }
}

.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}

.popup-container {
  padding: 94px 28px 0 28px;
  width: 500px;
  height: 100%;

  .popup-title {
    padding-bottom: 40px;
    border-bottom: 1px solid #ebebeb;

    img {
      width: 80px;
      height: 80px;
      display: block;
      border-radius: 100%;
      margin-right: 30px;
    }

    .user-name {
      .name {
        font-size: 32px;
        color: #333333;
        font-weight: bold;
      }

      .dept {
        font-size: 22px;
        color: #666666;
        margin-top: 10px;
      }
    }
  }

  .cell-wrap {
    margin-top: 42px;

    .cell-label {
      font-size: 24px;
      color: #222222;
      margin-left: 20px;
    }

    .cell-icon {
      color: #999;
    }

    .cell-icon1 {
      text-align: center;
      display: inline-block;
      width: 40px;
    }

    .my-van-cell {
      margin-bottom: 46px;
    }
  }

  .logout-btn {
    width: 331px;
    height: 70px;
    background: rgba(236, 247, 255, 0.8);
    border-radius: 35px 35px 35px 35px;
    font-size: 28px;
    color: #0091ff;
    text-align: center;
    line-height: 70px;
    position: absolute;
    bottom: 250px;
    left: 85px;
  }

  ::v-deep .van-icon {
    font-size: 26px !important;
  }
}

::v-deep .van-dropdown-menu__bar {
  box-shadow: none;
}

::v-deep .van-dropdown-item__option {
  text-align: center;
}

::v-deep .van-cell__value {
  display: none;
}

::v-deep .van-cell::after {
  display: none;
}

// ::v-deep .van-popover__content {
//   width: 300px !important;
// }
// ::v-deep .van-popover__action-text {
//   text-align: center;
// }
// ::v-deep .van-popover__action {
//   width: 300px !important;
//   height: 60px !important;
// }

// ::v-deep .van-popover__action-text {
//   font-size: 30px;
// }
// 弹窗
::v-deep .van-popup--center {
  border-radius: 40px;
  background: url('../../assets/images/version_bg.png') no-repeat center;
  background-size: 100% 100%;
}

.pop-content {
  width: 520px;
  height: 706px;
  padding: 260px 42px 20px 42px;
  position: relative;

  .new-version-con {
    position: absolute;
    top: 60px;
    right: 60px;
    color: #fff;

    .label {
      font-size: 32px;
    }

    .value {
      font-size: 30px;
      margin-top: 20px;
    }
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 30px;
  }

  .con {
    .item-con {
      font-size: 24px;
      color: #999999;
      line-height: 40px;
    }
  }

  .btn {
    display: block;
    width: 370px;
    height: 70px;
    background: linear-gradient(177deg, #8596ff 0%, #58b7ff 100%);
    border-radius: 35px 35px 35px 35px;
    text-align: center;
    line-height: 70px;
    font-size: 30px;
    color: #fff;
    margin: 30px auto;
  }

  .tips {
    font-size: 20px;
    color: #bbbbbb;
    text-align: center;
  }
}
</style>
